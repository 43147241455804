<template>
    <section class="flex justify-center items-center">
        <div class="container min-h-screen flex flex-col justify-center items-center px-[10px]">
            <div class="py-[60px] absolute top-0 w-full flex justify-between pr-[20px] items-center">
                <div class="flex items-center px-[20px] py-[5px]">
                    <div class="w-[35px] h-[35px] flex justify-center items-center relative">
                        <img src="../assets/logo.jpg" alt="" />
                    </div>
                    <h1 class="text-gray-700 font-bold text-xl mx-[10px] capitalize">Pomme city</h1>
                </div>
                <div class="flex justify-center items-center">
                    <a href="#" class="mx-[5px] text-xl">
                        <i class="fa-solid fa-circle-info"></i>
                    </a>
                    <a href="#" class="mx-[5px] text-xl">
                        <i class="fa-solid fa-circle-question"></i>
                    </a>
                </div>
            </div>
            <div class="flex my-[20px] justify-center items-center text-center">
                <h1 class="text-black text-xl font-bold">Salut ! Bon retour parmi nous 🙌</h1>
            </div>
            <form @submit.prevent="login()" class="flex flex-col justify-center items-center w-full relative max-w-[700px]">
                <div class="py-[10px] w-full px-[10px] relative">
                    <label for="email" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Email</label>
                    <input type="text" v-model="email" id="email" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" placeholder="E.x: serge01king" />
                </div>
                <div class="py-[10px] w-full px-[10px] relative">
                    <label for="password" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Mot de passe</label>
                    <input type="password" v-model="password" id="password" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" placeholder="E.x: *********" />
                </div>
                <div class="py-[10px] w-full px-[10px] relative">
                    <div @click="gotoResetPage()" class="font-bold text-sm text-black hover:underline duration-300 cursor-pointer">Mot de passe oublié ?</div>
                </div>
                <div  class="py-[10px] w-full px-[10px] flex justify-end items-center relative">
                    <input type="submit" value="Se connecter" class="py-[10px] px-[20px] cursor-pointer rounded-[5px] text-sm font-bold text-white bg-blue-500 hover:bg-blue-700 duration-300" :disabled="isLoading" />
                </div>
                <div v-if="isLoading" class="loader mt-4"></div>
                <div v-if="error" class="text-red-500 mt-4">{{ error }}</div>
            </form>
        </div>
    </section>
</template>
  
<script setup>
import { useAuthStore } from '@/store/auth';
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
const router = useRouter();

const email = ref('');
const password = ref('');
const isLoading = ref(false);
const error = ref(null);

function gotoResetPage() {
  router.push('/reset')
}

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const login = async () => {
  isLoading.value = true;
  error.value = null;
  try {
    const response = await axios.post('/api/login', { email: email.value, password: password.value });
    authStore.setToken(response.data.token);
    authStore.setUser(response.data.user);
    router.push("/dashboard")
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
      error.value = err.response.data.error;
    } else {
      error.value = 'Erreur lors de la connexion';
    }
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  if(user.value) {
    router.push("/dashboard")
  }
})
</script>

<style>
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
