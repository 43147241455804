import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '../store/auth';
import DashboardView from '../views/DashboardView.vue'
import LoginView from '@/views/LoginView.vue'
import ProduitsView from '@/views/ProduitsView.vue'
import ClientsView from '@/views/ClientsView.vue'
import ProfilView from '@/views/ProfilView.vue'
import EmployesView from '@/views/EmployesView.vue'
import StockView from '@/views/StockView.vue'
import VenteView from '@/views/VenteView.vue'
import HistoriqueView from '@/views/HistoriqueView.vue'
import ResetView from '@/views/ResetView.vue';
import SiteView from '@/views/SiteView.vue'
import TransfereView from '@/views/TransfereView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: { requiresAuth: false },
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetView,
    meta: { requiresAuth: false },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: '/employes',
    name: 'employes',
    component: EmployesView,
    meta: { requiresAuth: true },
  },
  {
    path: '/produits',
    name: 'produits',
    component: ProduitsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/clients',
    name: 'clients',
    component: ClientsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/profil',
    name: 'profil',
    component: ProfilView,
    meta: { requiresAuth: true },
  },
  {
    path: '/stock',
    name: 'stock',
    component: StockView,
    meta: { requiresAuth: true },
  },
  {
    path: '/vente',
    name: 'vente',
    component: VenteView,
    meta: { requiresAuth: true },
  },
  {
    path: '/historique',
    name: 'historique',
    component: HistoriqueView,
    meta: { requiresAuth: true },
  },
  {
    path: '/site',
    name: 'site',
    component: SiteView,
    meta: { requiresAuth: true },
  },
  {
    path: '/transfere',
    name: 'transfere',
    component: TransfereView,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.token) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router
