<template>
    <section class="flex justify-center items-center">
      <div class="container min-h-screen flex flex-col justify-center items-center px-[10px]">
        <div class="py-[60px] absolute top-0 w-full flex justify-between pr-[20px] items-center">
            <div class="flex items-center px-[20px] py-[5px]">
                <div class="w-[35px] h-[35px] flex justify-center items-center relative">
                    <img src="../assets/logo.jpg" alt="" />
                </div>
                <h1 class="text-gray-700 font-bold text-xl mx-[10px] capitalize">Pomme city</h1>
            </div>
            <div class="flex justify-center items-center">
                <a href="#" class="mx-[5px] text-xl">
                    <i class="fa-solid fa-circle-info"></i>
                </a>
                <a href="#" class="mx-[5px] text-xl">
                    <i class="fa-solid fa-circle-question"></i>
                </a>
            </div>
        </div>
        <div v-if="step === 1" class="w-full max-w-[400px] flex flex-col justify-center items-center">
          <h2 class="text-xl font-bold mb-4">Mot de passe oublié</h2>
          <form @submit.prevent="sendCode" class="flex w-full relative justify-center items-center flex-col">
            <div class="py-[10px] w-full px-[10px] relative">
              <label for="email" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Email</label>
              <input type="email" v-model="email" id="email" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" placeholder="E.x: serge01king@example.com" required />
            </div>
            <div class="py-[10px] w-full px-[10px] flex justify-end items-center relative">
              <input type="submit" value="Envoyer le code" class="py-[10px] px-[20px] cursor-pointer rounded-[5px] text-sm font-bold text-white bg-blue-500 hover:bg-blue-700 duration-300" :disabled="isLoading" />
            </div>
            <div v-if="isLoading" class="loader mt-4"></div>
            <div v-if="error" class="text-red-500 mt-4">{{ error }}</div>
          </form>
        </div>
        <div v-else-if="step === 2" class="w-full max-w-[400px] flex justify-center items-center flex-col">
          <h2 class="text-xl font-bold mb-4">Vérification du code</h2>
          <form @submit.prevent="verifyCode" class="flex w-full relative justify-center items-center flex-col">
            <div class="py-[10px] w-full px-[10px] relative">
              <label for="code" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Code</label>
              <input type="text" v-model="code" id="code" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" placeholder="Entrez le code reçu" required />
            </div>
            <div class="py-[10px] w-full px-[10px] relative">
              <label for="newPassword" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Nouveau mot de passe</label>
              <input type="password" v-model="newPassword" id="newPassword" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" placeholder="E.x: *********" required />
            </div>
            <div class="py-[10px] w-full px-[10px] flex justify-end items-center relative">
              <input type="submit" value="Réinitialiser le mot de passe" class="py-[10px] px-[20px] cursor-pointer rounded-[5px] text-sm font-bold text-white bg-blue-500 hover:bg-blue-700 duration-300" :disabled="isLoading" />
            </div>
            <div v-if="isLoading" class="loader mt-4"></div>
            <div v-if="error" class="text-red-500 mt-4">{{ error }}</div>
          </form>
        </div>
        <div v-else class="w-full max-w-[400px] flex justify-center items-center flex-col">
          <h2 class="text-xl font-bold mb-4">Mot de passe réinitialisé avec succès</h2>
          <p class="text-sm text-center">Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.</p>
          <button @click="goToLogin" class="mt-4 py-[10px] px-[20px] cursor-pointer rounded-[5px] text-sm font-bold text-white bg-blue-500 hover:bg-blue-700 duration-300">Retour à la connexion</button>
        </div>
      </div>
    </section>
</template>
  
<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const email = ref('');
const code = ref('');
const newPassword = ref('');
const isLoading = ref(false);
const error = ref(null);
const step = ref(1);
const router = useRouter();
  
const sendCode = async () => {
    isLoading.value = true;
    error.value = null;
    try {
      await axios.post('/api/send-reset-code', { email: email.value });
      step.value = 2;
    } catch (err) {
      error.value = 'Erreur lors de l\'envoi du code';
    } finally {
      isLoading.value = false;
    }
};
  
const verifyCode = async () => {
    isLoading.value = true;
    error.value = null;
    try {
      await axios.post('/api/verify-code-reset-password', { email: email.value, code: code.value, newPassword: newPassword.value });
      step.value = 3;
    } catch (err) {
      error.value = 'Erreur lors de la vérification du code';
    } finally {
      isLoading.value = false;
    }
};
  
const goToLogin = () => {
    router.push('/');
};
</script>
  
<style>
  /* Même style que précédemment pour le loader et autres */
</style>
  