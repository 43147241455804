<template>
  <main class="flex justify-center">
    <HeaderComponent />
    <section class="w-full lg:pl-[50px] lg:pt-[95px] pt-[95px] max-h-screen overflow-y-auto relative flex justify-start flex-col lg:p-[25px] p-[20px] h-auto min-h-screen bg-gray-200">
      <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[90%] max-w-[400px] flex flex-col">
          <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
              {{ errorMessage }}
          </div>
          <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
              {{ successMessage }}
          </div>
      </div>
      <div class="w-full flex flex-col justify-center items-center relative">
        <!-- Stock en dessous du seuil -->
        <div class="p-[20px] rounded-[10px] bg-white w-full">
          <h1 class="text-sm font-bold text-gray-800">Stock en dessous du seuil</h1>

          <div v-if="produitsSousSeuil.length > 0" class="w-full relative flex flex-col items-center mt-[5px]">
            <div v-for="produit in produitsSousSeuil" :key="produit._id" class="flex justify-between w-full p-[10px] bg-gray-100 rounded-[5px] my-[5px]">
              <div class="flex items-center">
                <img :src="produit.lotPhotos[0]" alt="" class="w-[50px] h-[50px] object-cover mr-[10px]">
                <div class="flex flex-col">
                  <span class="text-sm font-bold text-gray-800">{{ produit.lotName }}</span>
                  <div class="flex items-center">
                    <span class="text-xs text-gray-500 mr-[5px]">{{ produit.lotCategori }}</span>
                    <h1 v-if="produit.capacities.length && produit.colors.length" class="text-xs ml-[5px] font-bold text-gray-800">
                      {{ produit.capacities[0] }} - {{ produit.colors[0] }}
                    </h1> 
                  </div>
                </div>
              </div>
              <span class="text-sm font-bold text-center text-red-600">{{ produit.quantite }} Articles</span>
            </div>
          </div>
        
          <div v-else class="text-center h-[150px] flex justify-center items-center text-gray-500 text-sm mt-[10px]">
            Aucun produit en dessous du seuil.
          </div>
        </div>
        <!-- Vue d'ensemble -->
        <div class="w-full hidden py-[10px] mt-[20px]">
          <h1 class="text-sm font-bold text-gray-800">Vue d'ensemble</h1>
          <div class="flex w-full relative items-center my-[10px] lg:flex-row flex-col">
            <div class="lg:w-[calc(50%-10px)] w-full lg:mr-[10px] my-[10px] lg:my-0 bg-white flex justify-between items-center p-[15px] rounded-[5px]">
              <div class="flex items-center">
                <div class="w-[50px] h-[50px] flex justify-center items-center relative overflow-hidden">
                  <img src="../assets/images/profil.jpeg" alt="" class="absolute w-full h-full object-cover">
                </div>
                <div class="flex flex-col mx-[10px]">
                  <h1 class="text-sm font-bold text-gray-800 uppercase">Parakou</h1>
                  <span class="mt-[5px] text-xs font-bold text-gray-500">Pomme City</span>
                </div>
              </div>
              <div class="flex flex-col">
                <span class="text-gray-600 text-xs font-semibold">Stock restant</span>
                <h1 :class="{'text-red-600': getStockTotal('Stock Parakou') + getStockTotal('Magasin Parakou') < 100, 'text-green-600': getStockTotal('Stock Parakou') + getStockTotal('Magasin Parakou') >= 100 }" class="text-sm font-bold mt-[5px]">{{ getStockTotal('Stock Parakou') + getStockTotal('Magasin Parakou') }} Articles</h1>
              </div>
            </div>
            <div class="lg:w-[calc(50%-10px)] w-full lg:ml-[10px] my-[10px] lg:my-0 bg-white flex justify-between items-center p-[15px] rounded-[5px]">
              <div class="flex items-center">
                <div class="w-[50px] h-[50px] flex justify-center items-center relative overflow-hidden">
                  <img src="../assets/images/cotonou.jpg" alt="" class="absolute w-full h-full object-cover">
                </div>
                <div class="flex flex-col mx-[10px]">
                  <h1 class="text-sm font-bold text-gray-800 uppercase">Cotonou</h1>
                  <span class="mt-[5px] text-xs font-bold text-gray-500">Pomme City</span>
                </div>
              </div>
              <div class="flex flex-col">
                <span class="text-gray-600 text-xs font-semibold">Stock restant</span>
                <h1 :class="{'text-red-600': getStockTotal('Stock Cotonou') + getStockTotal('Magasin Cotonou') < 100, 'text-green-600': getStockTotal('Stock Cotonou') + getStockTotal('Magasin Cotonou') >= 100 }" class="text-sm font-bold mt-[5px]">{{ getStockTotal('Stock Cotonou') + getStockTotal('Magasin Cotonou') }} Articles</h1>
              </div>
            </div>
          </div>
        </div>

        <!-- Analyse précise -->
        <div class="w-full py-[10px] mt-[20px]">
          <div class="flex w-full relative justify-between flex-wrap items-center">
            <h1 class="text-xl font-bold text-gray-800">{{ buttons[selectedButton] }}</h1>
            <div class="flex w-full mt-[20px] lg:mt-0 lg:w-auto justify-center items-center">
              <button v-for="(button, index) in buttons"
                      :key="index"
                      @click="selectButton(index)"
                      :class="{'bg-gray-800 text-white': selectedButton === index, 'bg-gray-200 text-black': selectedButton !== index, 'hidden lg:flex': stockType == false}"
                      class="ml-[10px] text-xs duration-300 font-bold text-gray-600 bg-gray-100 p-[10px]">
                {{ button }}
              </button>
            </div>
            <div @click="toggleStock()" class="w-[30px] duration-200 lg:hidden h-[30px] text-sm font-bold rounded-[50%] bg-gray-50 flex justify-center items-center absolute top-0 right-0">
              <i v-if="stockType == false" class="fa-solid fa-add"></i>
              <i v-else class="fa-solid fa-remove"></i>
            </div>
          </div>
          <div class="flex w-full relative items-center my-[10px] flex-col">
            <div class="w-full bg-white rounded-[10px] md:h-[550px] h-auto flex justify-center flex-col items-center relative">
              <div class="flex w-full justify-between items-center px-[20px] py-[10px]">
                <div class="flex flex-col">
                  <h1 class="font-bold text-sm md:text-lg text-gray-800">{{ buttons[selectedButton] }}</h1>
                  <span class="mt-[5px] text-xs lg:text-sm text-gray-400">{{ buttons[selectedButton] === 'Stock Général' ? 'Tous les emplacements' : buttons[selectedButton] }}</span>
                </div>
                <div class="flex flex-col items-end">
                  <h1 class="font-bold text-sm md:text-lg text-gray-800">{{ getTotalFilteredProduits() }}</h1>
                  <span class="mt-[5px] text-xs lg:text-sm text-gray-400">Dernière mise à jour {{ dayjs().format('DD/MM/YYYY') }}</span>
                </div>
              </div>
              <div class="w-full h-auto relative">
                <canvas id="myChart"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import HeaderComponent from "../components/HeaderComponent.vue";
import { ref, computed, onMounted, watch } from 'vue';
import axios from 'axios';
import dayjs from 'dayjs';
import { useAuthStore } from '@/store/auth';
import { Chart, registerables } from 'chart.js';
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, Filler } from 'chart.js';
import { useRouter } from 'vue-router';
const router = useRouter();

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, Filler);


Chart.register(...registerables);

const stockType = ref(false);
const buttons = ['Stock Général', 'Stock Parakou', 'Stock Cotonou', 'Magasin Parakou', 'Magasin Cotonou'];
const selectedButton = ref(0);
const authStore = useAuthStore();
const user = computed(() => authStore.user);

const errorMessage = ref('');
const successMessage = ref('')
const produits = ref([]);
const filteredProduits = ref([]);
const produitsSousSeuil = ref([]);
const lots = ref([])

const fetchProduits = async () => {
  try {
    const response = await axios.get('/api/lots');
    produits.value = response.data.flatMap(lot => 
      lot.produits.map(produit => ({
        ...produit,
        lotName: lot.name,
        lotPhotos: lot.photos,
        lotCategori: lot.categorie,
        lotSeuil: lot.seuil
      }))
    );

    // Vérifier si un produit est en dessous de son seuil
    produitsSousSeuil.value = produits.value.filter(produit => {
      const totalQuantite = produit.quantite;
      return totalQuantite < produit.lotSeuil;
    });

    filterProduitsByStock(); // Filtre les produits dès qu'ils sont récupérés
    createChart(); // Crée le graphique après la récupération des produits
  } catch (error) {
    errorMessage.value = 'Erreur lors du chargement des produits.';
    hideErrorMessageAfterDelay();
  }
};

const selectButton = (index) => {
  selectedButton.value = index;
  filterProduitsByStock();
  createChart(); // Met à jour le graphique lors du changement de bouton
};

const toggleStock = () => {
  stockType.value = !stockType.value;
};

const filterProduitsByStock = () => {
  const stockName = buttons[selectedButton.value];
  if (stockName === 'Stock Général') {
    filteredProduits.value = produits.value.filter(produit => 
      ['Stock Parakou', 'Stock Cotonou', 'Magasin Parakou', 'Magasin Cotonou'].includes(produit.stock)
    );
  } else {
    filteredProduits.value = produits.value.filter(produit => produit.stock === stockName);
  }
};

const getTotalFilteredProduits = () => {
  return filteredProduits.value.reduce((acc, produit) => acc + produit.quantite, 0);
};

const getStockTotal = (stockName) => {
  return produits.value.filter(produit => produit.stock === stockName).reduce((acc, produit) => acc + produit.quantite, 0);
};

let chart;

const createChart = () => {
  const ctx = document.getElementById('myChart').getContext('2d');

  if (chart) {
    chart.destroy();
  }

  // Initialize data for each month
  const monthlyData = Array(12).fill(0);

  // Calculate the total quantity per month
  filteredProduits.value.forEach(produit => {
    const month = new Date(produit.createdAt).getMonth(); // Extract month from creation date
    monthlyData[month] += produit.quantite;
  });

  // Create the chart
  chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [{
        label: 'Nombre d\'articles',
        data: monthlyData,
        borderWidth: 1,
        borderColor: '#36A2EB',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        pointBackgroundColor: 'rgba(54, 162, 235, 1)',
        pointBorderColor: '#fff',
        fill: true,
        tension: 0.4
      }]
    },
    options: {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  });
};


const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 5000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 10000);
};


onMounted(() => {
  fetchProduits();
  if(!user.value.isSuper) {
    router.push("/dashboard")
  }
});

watch(selectedButton, filterProduitsByStock);
</script>

<style scoped>
/* Ajoutez ici votre CSS personnalisé */

canvas {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
