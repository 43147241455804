<template>
    <main class="flex justify-center">
        <HeaderComponent/>
        <section class="w-full lg:pl-[50px] lg:pt-[95px] pt-[95px] max-h-screen overflow-y-auto relative flex justify-start flex-col lg:p-[25px] p-[20px] h-auto min-h-screen bg-gray-200">
            <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[90%] max-w-[400px] flex flex-col">
                <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
                    {{ errorMessage }}
                </div>
                <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
                    {{ successMessage }}
                </div>
            </div>
            <div class="w-full flex flex-col justify-center items-center relative">
                <div class="flex w-full justify-center items-center relative lg:flex-row flex-col">
                    <div class="flex w-full lg:w-[35%] justify-center relative lg:mr-[20px] items-center flex-col">
                        <div class="h-[150px] w-full rounded-[10px] my-[10px] p-[10px] flex justify-center items-center flex-col relative bg-white">
                            <h1 class="text-2xl font-bold text-center my-[5px] ">
                                {{ totalOrders }}
                            </h1>
                            <span class="text-center font-semibold text-sm text-gray-400">Nombre de nouveau client</span>
                        </div>
                        <div class="h-[200px] w-full rounded-[10px] my-[10px] p-[10px] flex justify-center items-center flex-col relative bg-white">
                            <div class="py-[15px] w-full px-[20px] relative flex justify-between items-start">
                                <div class="flex flex-col justify-center items-center">
                                    <h1 class="font-bold text-2xl text-gray-800">{{ boutiqueOrders }}</h1>
                                    <span class="mt-[5px] text-xs text-center font-semibold text-gray-400">Achats en boutique</span>
                                </div>
                                <div class="flex flex-col justify-center items-center">
                                    <h1 class="font-bold text-2xl text-orange-800">{{ onlineOrders }}</h1>
                                    <span class="mt-[5px] text-xs text-center font-semibold text-gray-400">Achats en ligne</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full lg:w-[calc(65%-20px)] rounded-[10px] my-[10px] lg:my-0 justify-center relative items-center bg-white md:h-[370px] h-auto">
                        <div class="chart-container">
                            <canvas id="myChart" ref="myChart"></canvas>
                        </div>
                    </div>
                </div>
                <div class="flex w-full justify-center items-start relative lg:flex-row flex-col">
                    <div class="flex w-full my-[10px] rounded-[10px] overflow-hidden justify-center relative items-start min-h-[500px] bg-white h-auto">
                        <ul class="flex w-full relative flex-col">
                            <li class="flex w-full justify-between items-center p-[10px] bg-gray-100 md:p-[20px]">
                                <div class="text-center md:flex hidden justify-center items-center text-sm font-bold text-gray-800 w-[50px]">Id</div>
                                <div class="text-left flex justify-start items-center text-sm font-bold text-gray-800 md:w-[calc(60%-150px)] w-[calc(60%-100px)]">Nom</div>
                                <div class="text-left flex justify-start items-center text-sm font-bold text-gray-800 w-[40%]">Email</div>
                                <div class="text-center flex justify-start items-center text-sm font-bold text-gray-800 w-[80px]">Numéro</div>
                            </li>
                            <li v-for="(client, index) in clients" :key="index" :index="index" class="flex w-full border-b-[0.5px] border-gray-500 justify-between items-center py-[20px] p-[10px] md:p-[20px]">
                                <div class="text-center md:flex hidden justify-center items-center text-sm font-bold text-gray-800 w-[50px]">#{{ index + 1 }}</div>
                                <div class="text-left flex justify-start items-center text-sm font-semibold text-gray-800 md:w-[calc(60%-150px)] w-[calc(60%-100px)]">
                                    {{ client.client ? client.client.prenoms + ' ' + client.client.nom : 'Nom non disponible' }}
                                </div>
                                <div class="text-left flex justify-start email pr-[20px] items-center text-sm text-gray-800 w-[40%]">
                                    {{ client.client ? client.client.email : 'Email non disponible' }}
                                </div>
                                <div class="text-center flex justify-start items-center text-sm font-bold text-gray-800 w-[80px]">
                                    {{ client.client ? client.client.numero : 'Numéro non disponible' }}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="hidden w-full lg:w-[45%] h-full justify-center relative lg:ml-[20px] items-center flex-col">
                        <div class="min-h-[200px] h-full overflow-y-auto rounded-[10px] w-full my-[10px] p-[10px] flex justify-start items-center flex-col relative bg-white">
                            <div class="flex w-full justify-between items-center my-[10px]">
                                <h1 class="text-sm font-bold text-gray-800">Commentaires sur le site web</h1>
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                            <div class="my-[15px] mb-[5px] relative w-full">
                                <ul class="flex flex-col justify-center items-center relative w-full px-[10px]">
                                    <li v-for="(commentaire, index) in commentaires" :key="index" class="flex items-start border-b-[0.5px] h-[120px] border-gray-500 py-[10px] justify-center relative w-full">
                                        <div class="flex h-full justify-between items-center flex-col relative">
                                            <div class="w-[35px] h-[35px] shadow-6xl min-w-[35px] text-blue-400 min-h-[35px] rounded-[50%] flex justify-center relative overflow-hidden items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 12q-1.65 0-2.825-1.175T8 8t1.175-2.825T12 4t2.825 1.175T16 8t-1.175 2.825T12 12m-8 8v-2.8q0-.85.438-1.562T5.6 14.55q1.55-.775 3.15-1.162T12 13t3.25.388t3.15 1.162q.725.375 1.163 1.088T20 17.2V20zm2-2h12v-.8q0-.275-.137-.5t-.363-.35q-1.35-.675-2.725-1.012T12 15t-2.775.338T6.5 16.35q-.225.125-.363.35T6 17.2zm6-8q.825 0 1.413-.587T14 8t-.587-1.412T12 6t-1.412.588T10 8t.588 1.413T12 10m0 8"/></svg>
                                            </div>
                                            <div class="w-[35px] h-[35px] min-w-[35px] cursor-pointer text-red-400 bg-red-500/5 min-h-[35px] rounded-[50%] flex justify-center relative overflow-hidden items-center">
                                                <i class="fa-solid fa-trash"></i>
                                            </div>
                                        </div>

                                        <div class="mx-[15px] w-full h-full flex justify-between flex-col">
                                            <div class="flex justify-between items-start w-full">
                                                <div class="">
                                                    <h1 class="text-sm font-bold">{{ commentaire.fullname }}</h1>
                                                    <h1 class="text-xs text-gray-500">{{ commentaire.email }}</h1>
                                                </div>
                                                <div class="flex">
                                                    <i class="fa-solid fa-star mx-[2.5px] text-orange-500 text-xs"></i>
                                                    <i class="fa-solid fa-star mx-[2.5px] text-orange-500 text-xs"></i>
                                                    <i class="fa-solid fa-star mx-[2.5px] text-orange-500 text-xs"></i>
                                                    <i class="fa-solid fa-star mx-[2.5px] text-orange-500 text-xs"></i>
                                                </div>
                                            </div>
                                            <p class="mt-[10px] text-xs ">
                                                {{ commentaire.content }}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from "../components/HeaderComponent.vue"
import { format } from 'date-fns';
import axios from 'axios'
import dayjs from 'dayjs';
import { onMounted, ref, computed } from 'vue'
import { useAuthStore } from '@/store/auth';
import { Line } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';
import { useRouter } from 'vue-router';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, Filler } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, Filler);

Chart.register(...registerables);
const router = useRouter();

const myChart = ref(null);

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const commentaires = ref([
    {
        fullname: 'mickael AGOSSA',
        email: 'agossamickael01@gmail.com',
        star: 5,
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, libero harum accusamus laboriosam possimus molestias quo repudiandae voluptates odit deleniti molestiae aut deserunt non? Vero, molestias porro. Expedita, minima omnis.'
    },
    {
        fullname: 'mickael AGOSSA',
        email: '',
        star: 5,
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, libero harum accusamus laboriosam possimus molestias quo repudiandae voluptates odit deleniti molestiae aut deserunt non? Vero, molestias porro. Expedita, minima omnis.'
    },
    {
        fullname: 'mickael AGOSSA',
        email: '',
        star: 5,
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, libero harum accusamus laboriosam possimus molestias quo repudiandae voluptates odit deleniti molestiae aut deserunt non? Vero, molestias porro. Expedita, minima omnis.'
    },
    {
        fullname: 'mickael AGOSSA',
        email: 'agossamickael01@gmail.com',
        star: 5,
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, libero harum accusamus laboriosam possimus molestias quo repudiandae voluptates odit deleniti molestiae aut deserunt non? Vero, molestias porro. Expedita, minima omnis.'
    },
])

const errorMessage = ref('')
const successMessage = ref('')
const totalOrders = ref(0)
const boutiqueOrders = ref(0)
const onlineOrders = ref(0)
const clients = ref([])
const salesByMonth = ref({
    online: {},
    boutique: {}
})

const fetchSalesData = async () => {
    try {
        const { data } = await axios.get('/api/sales/unique-client');
        clients.value = data
        totalOrders.value = data.length;
        boutiqueOrders.value = data.filter(sale => sale.vendeur.boutique !== 'En ligne').length;
        onlineOrders.value = data.filter(sale => sale.vendeur.boutique === 'En ligne').length;

        salesByMonth.value = { online: {}, boutique: {} };

        data.forEach(sale => {
            const month = format(new Date(sale.createdAt), 'MMMM');
            const isOnline = sale.vendeur.boutique === 'En ligne';

            if (isOnline) {
                salesByMonth.value.online[month] = (salesByMonth.value.online[month] || 0) + 1;
            } else {
                salesByMonth.value.boutique[month] = (salesByMonth.value.boutique[month] || 0) + 1;
            }
        });

        // Convertir les objets en tableaux pour le format attendu par le composant enfant
        salesByMonth.value.online = Object.entries(salesByMonth.value.online).map(([key, value]) => ({ month: key, count: value }));
        salesByMonth.value.boutique = Object.entries(salesByMonth.value.boutique).map(([key, value]) => ({ month: key, count: value }));


    } catch (error) {
        errorMessage.value = 'Erreur lors de la récupération des ventes'
        hideErrorMessageAfterDelay()
    }
};

let chart;

const createChart = () => {
  const ctx = document.getElementById('myChart').getContext('2d');

  if (chart) {
    chart.destroy();
  }

  const onlineData = Array(12).fill(0);
  const otherData = Array(12).fill(0);

  clients.value.forEach(sale => {
    const month = new Date(sale.createdAt).getMonth();
    if (sale.vendeur.boutique === 'En ligne') {
      onlineData[month] += 1;
    } else {
      otherData[month] += 1;
    }
  });

  chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'En ligne',
          borderColor: '#36A2EB',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          pointBackgroundColor: 'rgba(54, 162, 235, 1)',
          pointBorderColor: '#fff',
          data: onlineData,
          fill: true,
          tension: 0.4
        },
        {
          label: 'Cotonou - Parakou',
          borderColor: '#FF6384',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          pointBackgroundColor: 'rgba(255, 99, 132, 1)',
          pointBorderColor: '#fff',
          data: otherData,
          fill: true,
          tension: 0.4
        }
      ]
    }
  });
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 5000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 10000);
};

onMounted(async () => {
  await fetchSalesData();
  createChart();
  if(!user.value.isSuper) {
    router.push("/dashboard")
  }
});

</script>

<style>
.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.chart-container-D {
  height: 100px;
  width: 100px;
}
p {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.email {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
</style>