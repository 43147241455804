<template>
    <main v-if="user" class="flex justify-center">
      <HeaderComponent/>
      <section class="w-full lg:pl-[50px] lg:pt-[95px] pt-[95px] max-h-screen overflow-y-auto relative flex justify-start flex-col lg:p-[25px] p-[20px] h-auto min-h-screen bg-gray-200">
        <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[90%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <div class="absolute z-[0] w-full h-[200px] top-[70px] bg-gray-800 left-0 right-0"></div>
        <div class="w-full flex flex-col lg:flex-row justify-center items-center relative">
          <div class="flex w-full lg:flex-row flex-col relative pb-[100px] justify-center lg:items-start items-center top-[80px]">
            <div class="lg:w-4/12 w-full flex flex-col relative bg-white m-[10px] overflow-hidden rounded-[10px]">
              <div class="w-full h-[200px] p-[20px] flex flex-col justify-center items-center">
                <div class="w-[115px] h-[115px] rounded-[50%] relative flex justify-center items-center">
                  <div class="bg-white w-[35px] h-[35px] absolute bottom-[5px] right-[0] flex justify-center items-center rounded-[50%] z-10">
                    <div class="w-[30px] h-[30px] rounded-[50%] bg-blue-600 flex justify-center items-center text-white cursor-pointer"><i class="fa-solid fa-camera"></i></div>
                  </div>
                  <img src="../assets/images/icone.png" alt="" class="w-full h-full rounded-[50%] absolute object-cover">
                </div>
                <div class="mt-[10px] flex w-full justify-center items-center flex-col">
                  <h1 class="font-bold text-sm text-gray-800">{{ user.firstname }} {{ user.lastname }}</h1>
                  <h2 class="text-sm font-semibold text-gray-500">{{ user.email }}</h2>
                </div>
              </div>
              <div class="p-[15px] hidden md:flex justify-between items-center">
                <h1 class="text-sm font-bold text-gray-600">Poste :</h1>
                <span class="text-gray-800 text-sm font-bold">{{ user.poste }}</span>
              </div>
              <div class="p-[15px] hidden md:flex justify-between items-center">
                <h1 class="text-sm font-bold text-gray-600">Identifiant Employé :</h1>
                <span class="text-gray-800 text-sm font-bold">{{ user.id }}</span>
              </div>
              <div class="p-[15px] hidden md:flex justify-between items-center">
                <h1 class="text-sm font-bold text-gray-600">Identifiant personnel :</h1>
                <span class="text-gray-800 text-sm font-bold">{{ user.username }}</span>
              </div>
              <div class="pt-[20px] hidden md:flex justify-center items-center">
                <button class="text-white px-[20px] w-full py-[10px] text-center bg-gray-800 text-sm font-bold">
                  Désactiver les notifications
                </button>
              </div>
            </div>
            <div class="lg:w-8/12 w-full flex flex-col relative p-[10px] md:p-[20px] m-[10px] rounded-[10px] bg-white">
              <div class="flex justify-start items-center">
                <div @click="toggleInfo()" :class="{'font-bold text-gray-800 border-gray-800': information==true, 'text-gray-500 border-gray-300': information==false}" class="h-[50px] text-sm w-auto border-b-2 lg:border-b-4 cursor-pointer pr-[10px] flex justify-center items-center">Informations</div>
                <div @click="togglePassword()" :class="{'font-bold text-gray-800 border-gray-800': password==true, 'text-gray-500 border-gray-300': password==false}" class="h-[50px] text-sm w-auto border-b-2 lg:border-b-4 cursor-pointer pr-[10px] flex justify-center items-center min-w-[110px]">Mot de passe</div>
                <div class="h-[50px] text-sm w-full border-b-2 lg:border-b-4 border-gray-300"></div>
              </div>
              <div v-if="information" class="w-full flex pt-[20px] justify-center items-center flex-col relative">
                <div class="flex w-full relative md:justify-between items-center justify-center md:flex-row flex-col ">
                  <div class="my-[10px] md:w-[calc(50%-10px)] w-full relative flex flex-col">
                    <label for="firstname" class="text-sm font-bold">Prénoms <span class="text-red-500">*</span></label>
                    <input type="text" v-model="user.firstname" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                  </div>
                  <div class="my-[10px] md:w-[calc(50%-10px)] w-full relative flex flex-col">
                    <label for="lastname" class="text-sm font-bold">Nom <span class="text-red-500">*</span></label>
                    <input type="text" v-model="user.lastname" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                  </div>
                </div>
                <div class="w-full flex flex-col relative my-[10px]">
                  <label for="email" class="text-sm font-bold">Email <span class="text-red-500">*</span></label>
                  <input type="email" v-model="user.email" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                </div>
                <div class="w-full flex flex-col relative my-[10px]">
                  <label for="phone" class="text-sm font-bold">Numéro <span class="text-red-500">*</span></label>
                  <input type="text" v-model="user.phone" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                </div>
                <div class="w-full flex flex-col relative my-[10px]">
                  <button @click="updateUser" class="p-[10px] px-[20px] text-sm font-bold bg-gray-800 text-white rounded-[5px]">Modifier</button>
                </div>
              </div>
              <div v-if="password" class="w-full flex pt-[20px] justify-center items-center flex-col relative">
                <div class="w-full flex flex-col relative my-[10px]">
                  <label for="currentPassword" class="text-sm font-bold">Ancien mot de passe <span class="text-red-500">*</span></label>
                  <input type="password" v-model="currentPassword" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                </div>
                <div class="w-full flex flex-col relative my-[10px]">
                  <label for="newPassword" class="text-sm font-bold">Nouveau mot de passe <span class="text-red-500">*</span></label>
                  <input type="password" v-model="newPassword" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                </div>
                <div class="w-full flex flex-col relative my-[10px]">
                  <label for="confirmPassword" class="text-sm font-bold">Confirmer le nouveau mot de passe <span class="text-red-500">*</span></label>
                  <input type="password" v-model="confirmPassword" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                </div>
                <div class="w-full flex flex-col relative my-[10px]">
                  <button @click="changePassword" class="p-[10px] px-[20px] text-sm font-bold bg-gray-800 text-white rounded-[5px]">Modifier</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </template>
  
  <script setup>
  import HeaderComponent from "../components/HeaderComponent.vue"
  import { ref, onMounted } from 'vue'
  import axios from 'axios'
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';

const authStore = useAuthStore();
const user = computed(() => authStore.user);


  const errorMessage = ref('')
  const successMessage = ref('')
  
  const information = ref(true)
  const password = ref(false)
  const currentPassword = ref('')
  const newPassword = ref('')
  const confirmPassword = ref('')

  
  const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 5000);
  };
  
  const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 10000);
  };
  
  function toggleInfo() {
    information.value = true 
    password.value = false
  }
  
  function togglePassword() {
    password.value = true 
    information.value = false
  }
  
  const updateUser = async () => {
    try {
      await axios.put(`/api/users/${user.value._id}`, user.value)
      localStorage.setItem('user', JSON.stringify(user.value));
      successMessage.value = 'Informations mises à jour avec succès'
      hideSuccessMessageAfterDelay()
    } catch (error) {
      errorMessage.value = 'Erreur lors de la mise à jour des informations'
      hideErrorMessageAfterDelay()
    }
  }
  
  const changePassword = async () => {
    if (newPassword.value !== confirmPassword.value) {
      errorMessage.value = 'Les nouveaux mots de passe ne correspondent pas'
      hideErrorMessageAfterDelay()
      return
    }
    try {
      await axios.post('/api/reset-password', {
        email: user.value.email,
        oldPassword: currentPassword.value,
        newPassword: newPassword.value
      })
      successMessage.value = 'Mot de passe mis à jour avec succès'
      hideSuccessMessageAfterDelay()
      currentPassword.value = ''
      newPassword.value = ''
      confirmPassword.value = ''
    } catch (error) {
      errorMessage.value = 'Erreur lors de la mise à jour du mot de passe'
      hideErrorMessageAfterDelay()
    }
  }
  
  onMounted(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      user.value = JSON.parse(userData);
    }
  });
  </script>
  
  <style scoped>
  /* Ajoutez vos styles ici */
  </style>
  