<template>
    <main class="flex justify-center">
      <HeaderComponent />
      <section class="w-full lg:pl-[50px] lg:pt-[95px] pt-[95px] max-h-screen overflow-y-auto relative flex justify-start flex-col lg:p-[25px] p-[20px] h-auto min-h-screen bg-gray-200">
        <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[90%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <div class=" w-full min-h-[250px] mb-[20px] h-auto z-10 relative mx-auto">
              <h2 class="text-lg font-bold mb-4">Transférer un Produit</h2>
                
              <input
                v-model="query"
                @input="searchProduits(query)"
                type="text"
                placeholder="Rechercher un produit..."
                class="w-full p-2 border border-gray-300 outline-none rounded mb-4"
              />
                
              <ul v-if="suggestions.length" class="border border-gray-300 rounded bg-white z-10 mb-4 max-h-40 overflow-y-auto">
                <li
                  v-for="produit in suggestions"
                  :key="produit.imei1"
                  @click="selectProduit(produit)"
                  class="p-2 cursor-pointer hover:bg-gray-100"
                >
                  {{ produit.imei1 }} - {{ produit.lotName }}
                </li>
              </ul>
          
              <div v-if="selectedProduit" class="mt-4">
                <p><strong>Stock Actuel:</strong> {{ selectedProduit.stock }}</p>
            
                <input
                  v-model="newStock"
                  type="text"
                  placeholder="Définir un nouveau stock"
                  class="w-full p-2 border border-gray-300 outline-none rounded mb-4"
                />
            
                <button
                  @click="sendTransfere()"
                  :disabled="isPending"
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {{ isPending ? 'En attente...' : 'Soumettre le transfère' }}
                </button>
              </div>
        </div>
        <div class="w-full relative flex justify-center items-center flex-col ">
          <h1 class="text-2xl w-full font-bold text-gray-800">Liste des Transfères</h1>
          <div v-if="transferes && transferes.length != 0" class="w-full flex flex-col justify-center items-center relative">
            <div v-for="(transfere, index) in transferes" :key="index" class="my-[10px] p-[10px] w-full md:rounded-[20px] rounded-[10px] shadow-4xl bg-gray-200 border border-gray-300 flex flex-col justify-center items-center relative">
              <button v-if="user.isSuper" @click="initierMiseAJourStock(transfere)" class="w-[30px] h-[30px] rounded-[50%] flex justify-center items-center absolute right-[-10px] top-[-10px]" :class="{'bg-gray-200 border border-green-600 text-green-600': !transfere.isValide, 'bg-green-600 border border-green-600 text-white': transfere.isValide}">
                <i class="fa-solid fa-check"></i>
              </button>
              <div class="flex w-full">
                <div class="flex items-center">
                  <h1 class=" mr-[10px] text-left text-gray-800 font-bold text-sm">
                    {{ transfere.nom }}
                  </h1>
                  <p class="text-sm font-semibold text-center text-gray-500">
                    {{ transfere.stockDepart }}
                  </p>
                  <button class="w-[30px] h-[30px] min-h-[30px] min-w-[30px] mx-[10px] flex justify-center items-center relative bg-white rounded-[50%]">
                    <i class="fa-solid fa-exchange text-sm font-semibold text-gray-500"></i>
                  </button>
                  <p class="text-sm font-semibold text-center text-gray-500">
                    {{ transfere.stockFinale }}
                  </p>
                </div>
                <div></div>
              </div>
              <div class="w-full flex items-center justify-between">
                <p class="text-xs text-left font-bold text-gray-500">
                  Par: {{ transfere.firstname ? transfere.firstname : 'Prénoms' }} {{ transfere.lastname ? transfere.lastname : 'Nom' }}
                </p>
                <span v-if="!transfere.isValide" class="text-[10px] font-semibold text-gray-700">
                  En attente...
                </span>
                <span v-if="transfere.isValide" class="text-[10px] font-semibold text-green-700">
                  Validé
                </span>
              </div>
            </div>
          </div>
          <div v-if="transferes.length == 0" class="w-full h-[200px] flex justify-center items-center text-sm font-bold text-gray-800">
            Aucun transfère trouvé
          </div>
        </div>
      </section>
    </main>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import HeaderComponent from '../components/HeaderComponent.vue';
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const query = ref('');
const suggestions = ref([]);
const selectedProduit = ref(null);
const newStock = ref('');
const isPending = ref(false);
const produits = ref([]);
const nom = ref('')
const stockFinale = ref('')
const stockDepart = ref('')
const errorMessage = ref('')
const successMessage = ref('')

// Fonction pour récupérer tous les produits, les formater et les stocker localement
const fetchProduits = async () => {
  try {
    const response = await axios.get('/api/lots'); 
    produits.value = response.data.map(lot => 
      lot.produits.map(produit => ({
        imei1: produit.imei1,
        imei2: produit.imei2,
        prix: produit.prix,
        stock: produit.stock,
        produitId: produit._id,
        prix_reduit: produit.prix_reduit,
        quantity: produit.quantite,
        lotName: lot.name,
        lotId: lot._id,
        photos: lot.photos,
        lotCategorie: lot.categorie,
      }))
    ).flat();
  } catch (error) {
    console.error('Erreur lors de la récupération des produits :', error);
  }
};

const searchProduits = (query) => {
  if (query.length > 0) {
    suggestions.value = produits.value.filter(produit => 
      produit.imei1.toLowerCase().includes(query.toLowerCase()) || 
      produit.lotName.toLowerCase().includes(query.toLowerCase())
    );
  } else {
    suggestions.value = [];
  }
};

const selectProduit = (produit) => {
  selectedProduit.value = produit;
  query.value = produit.lotName; 
  suggestions.value = []; 
};

const sendTransfere = async () => {
    if (!newStock.value) {
      alert('Veuillez définir un nouveau stock valide.');
      return;
    }
    isPending.value = true;
    try {
        const response = await axios.post('/api/transfere/send', 
        {
            nom: selectedProduit.value.lotName,
            idProduit: selectedProduit.value.produitId,
            firstname: user.value.firstname,
            lastname: user.value.lastname,
            stockDepart: selectedProduit.value.stock,
            stockFinale: newStock.value
        });
        if (response.status === 201 || response.status == 200) {
            successMessage.value = 'Transfere enregistré avec succès!';
            hideSuccessMessageAfterDelay();
        }
        // Réinitialiser les valeurs
        query.value = '';
        selectedProduit.value = null;
        newStock.value = '';
        stockDepart.value = ""
        stockFinale.value = ""
        fetchTransfere()
    } catch (error) {
        errorMessage.value = 'Erreur lors de l\'enregistrement du transfère.';
        hideErrorMessageAfterDelay()
    }
};
// Fonction pour activer/désactiver un transfert
const toggleActive = async (id) => {
  try {
    await axios.put(`/api/transfere/${id}/toggle-active/`);
    const index = transferes.value.findIndex((e) => e._id === id);
    if (index !== -1) {
      transferes.value[index].isValide = true;
    } else {
      console.warn(`Transfère avec ID ${id} non trouvé.`);
    }
  } catch (error) {
    errorMessage.value = 'Erreur lors du changement de statut.';
    console.error(error);
    hideErrorMessageAfterDelay();
  }
};
const initierMiseAJourStock = async (transfere) => {
  isPending.value = true; 
  try {
    const stockResponse = await axios.patch(`/api/produits/${transfere.idProduit}/update-stock`, {
      newStock: transfere.stockFinale,
    });

    // Vérification du succès de la mise à jour du stock
    if (stockResponse.status === 200 || stockResponse.status === 201) {
      await toggleActive(transfere._id);
    }

    await fetchTransfere();
  } catch (error) {
    errorMessage.value = 'Erreur lors de la mise à jour du stock ou du transfert.';
    hideErrorMessageAfterDelay()
  } finally {
    isPending.value = false; 
  }
};


const transferes = ref([])

const fetchTransfere = async () => {
    try {
        const response = await axios.get('/api/transfere/getAll');
        transferes.value = response.data;
    } catch (error) {
        errorMessage.value = 'Erreur lors de la récupération des transferes';
    }
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 5000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 10000);
};

onMounted(() => {
  fetchProduits();
  fetchTransfere()
});
</script>

<style scoped>
</style>
