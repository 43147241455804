<template>
    <div id="parent" :class="{'lg:translate-x-[-100%] lg:hidden lg:relative': !menu, 'lg:translate-x-0 lg:relative': menu, 'translate-x-0 fixed top-[70px] h-[calc(100vh-70px)]': menuM, 'translate-x-[-100%] fixed': !menuM}" class="w-[280px] min-w-[280px] lg:flex lg:translate-x-0 duration-500 h-screen bg-white shadow-4xl overflow-x-hidden z-[5000] overflow-y-auto left-0 top-0 bottom-0 justify-center p-[25px] items-center">
        <div class="relative w-full h-full  flex flex-col">
            <div class="w-full flex mb-[25px]  items-center px-[20px] py-[5px]">
                <div class="w-[35px] h-[35px] flex justify-center items-center relative"><img src="../assets/logo.jpg" alt=""></div>
                <h1 class="text-gray-700 font-bold text-xl mx-[10px] capitalize">Pomme city</h1>
            </div>
            <div class="flex w-full z-[5000] relative h-full flex-col justify-between items-center">
                <div id="parent" class="w-full relative mb-[100px] h-[calc(100vh-310px)] md:h-[calc(100vh-210px)] overflow-y-auto">
                    <div @click="goToDashboardPage()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600" :class="{'bg-orange-500 text-white font-bold': $route.path == '/dashboard'}">
                        <i class="fa-solid fa-house mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Dashboard</h1>
                    </div>
                    <div @click="goToVentePage()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600" :class="{'bg-orange-500 text-white font-bold': $route.path == '/vente'}">
                        <i class="fa-solid fa-credit-card mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Vente / Troc</h1>
                    </div>
                    <div @click="goToTransferePage()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600" :class="{'bg-orange-500 text-white font-bold': $route.path == '/transfere'}">
                        <i class="fa-solid fa-exchange mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Transfère</h1>
                    </div>
                    <div v-if="user.isSuper" @click="goToStockPage()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600" :class="{'bg-orange-500 text-white font-bold': $route.path == '/stock'}">
                        <i class="fa-solid fa-layer-group mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Stock</h1>
                    </div>
                    <div v-if="user.isSuper" @click="goToEmployesPage()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600" :class="{'bg-orange-500 text-white font-bold': $route.path == '/employes'}">
                        <i class="fa-solid fa-clipboard-user mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Employés</h1>
                    </div>
                    <div @click="goToProduitsPage()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600" :class="{'bg-orange-500 text-white font-bold': $route.path == '/produits'}">
                        <i class="fa-solid fa-tag mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Produits</h1>
                    </div>
                    <div v-if="user.isSuper" @click="goToClientsPage()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600" :class="{'bg-orange-500 text-white font-bold': $route.path == '/clients'}">
                        <i class="fa-solid fa-users mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Clients</h1>
                    </div>
                    <div v-if="user.isSuper" @click="goToSitePage()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600" :class="{'bg-orange-500 text-white font-bold': $route.path == '/site'}">
                        <i class="fa-solid fa-globe mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Site web</h1>
                    </div>
                    <div @click="goToHistoriquePage()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600" :class="{'bg-orange-500 text-white font-bold': $route.path == '/historique'}">
                        <i class="fa-solid fa-clock-rotate-left mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Historique</h1>
                    </div>
                </div>
                <div class=" absolute w-full left-0 bg-white z-40 md:bottom-0 bottom-[70px] ">
                    <div @click="goToProfilPage()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600" :class="{'bg-orange-500 text-white font-bold': $route.path == '/profil'}">
                        <i class="fa-solid fa-gear mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Profile</h1>
                    </div>
                    <div @click="handleLogout()" class="w-full flex items-center my-[10px] px-[20px] py-[10px] rounded-[5px] hover:bg-orange-500 hover:text-white duration-200 cursor-pointer text-gray-600">
                        <i class="fa-solid fa-right-to-bracket mt-[-2.5px] w-[20px]"></i>
                        <h1 class="flex capitalize text-sm mx-[10px] font-semibold hover:text-white duration-20">Se déconnecter</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div @click="toggleMenu" :class="{'left-0': !menu, 'left-[265px]': menu}" class="lg:flex hidden justify-center duration-300 items-center fixed z-[10000] rounded-[50%] shadow-6xl w-[30px] h-[30px] text-black text-sm font-bold top-[50%] translate-y-[-50%] bg-white cursor-pointer">
        <i v-if="menu" class="fa-solid fa-chevron-left"></i>
        <i v-else class="fa-solid fa-chevron-right"></i>
    </div>
    <div class="fixed top-0 left-0 right-0 h-[70px] bg-white z-20 w-full flex justify-center shadow-4xl">
        <div :class="{'lg:hidden': !menu,}" class="w-[280px] lg:flex hidden min-w-[280px]"></div>
        <div class="w-full relative flex justify-between items-center h-[70px] px-[20px] lg:px-[50px]">
            <div class="flex justify-center items-center">
                <h1 v-if="$route.path == '/dashboard'" class="text-gray-800 lg:text-xl text-sm font-bold">Dashboard</h1>
                <h1 v-else-if="$route.path == '/employes'" class="text-gray-800 lg:text-xl text-sm font-bold">Employés(8)</h1>
                <h1 v-else-if="$route.path == '/produits'" class="text-gray-800 lg:text-xl text-sm font-bold">Produits</h1>
                <h1 v-else-if="$route.path == '/clients'" class="text-gray-800 lg:text-xl text-sm font-bold">Clients</h1>
                <h1 v-else-if="$route.path == '/profil'" class="text-gray-800 lg:text-xl text-sm font-bold">Mon Profile</h1>
                <h1 v-else-if="$route.path == '/stock'" class="text-gray-800 lg:text-xl text-sm font-bold">Stock</h1>
                <h1 v-else-if="$route.path == '/vente'" class="text-gray-800 lg:text-xl text-sm font-bold">Faire une vente</h1>
                <h1 v-else-if="$route.path == '/site'" class="text-gray-800 lg:text-xl text-sm font-bold">Informations du site</h1>
                <h1 v-else-if="$route.path == '/historique'" class="text-gray-800 lg:text-xl text-sm font-bold">Historique des commandes</h1>
                <h1 v-else-if="$route.path == '/transfere'" class="text-gray-800 lg:text-xl text-sm font-bold">Transférer un produit</h1>
                <div class="w-[30px] h-[30px] cursor-pointer mx-[15px] rounded-[50%] bg-blue-50 text-gray-950 flex justify-center items-center text-sm">
                    <i class="fa-solid fa-search"></i>
                </div>
            </div>
            <div class="flex justify-center items-center">
                <div class="bg-gray-100 flex md:pr-[10px] rounded-[25px] cursor-pointer justify-center items-center">
                    <div class="w-[30px] h-[30px] rounded-[50%] relative overflow-hidden">
                        <img src="../assets/images/icone.png" alt="" class="w-full h-full absolute object-cover">
                    </div>
                    <span class="mx-[5px] text-sm hidden md:flex font-semibold text-gray-800">{{ user.firstname }} {{ user.lastname }}</span>
                </div>
                <span class="text-2xl w-[25px] flex justify-center items-center mx-[10px] text-gray-800 cursor-pointer lg:hidden" @click="toggleMenuM()">
                    <div v-if="!menuM"><i class="fa-solid fa-bars"></i></div>
                    <div v-else><i class="fa-solid fa-xmark"></i></div>
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';

const router = useRouter()
const menu = ref(true)
const menuM = ref(false)
const authStore = useAuthStore();
const user = computed(() => authStore.user);

const handleLogout = () => {
  authStore.logout();
  router.push({ name: 'Login' });
};

function goToDashboardPage() {
    router.push("/dashboard")
}
function goToClientsPage() {
    router.push("/clients")
}
function goToVentePage() {
    router.push("/vente")
}
function goToProduitsPage() {
    router.push("/produits")
}
function goToStockPage() {
    router.push("/stock")
}
function goToProfilPage() {
    router.push("/profil")
}
function goToEmployesPage() {
    router.push("/employes")
}
function goToSitePage() {
    router.push("/site")
}
function goToHistoriquePage() {
    router.push("/historique")
}
function goToTransferePage() {
    router.push("/transfere")
}

function toggleMenu() {
    menu.value = !menu.value
}
function toggleMenuM() {
    menuM.value = !menuM.value
}

</script>

<style>

</style>