<template>
    <main class="flex justify-center">
      <HeaderComponent />
      <section class="w-full lg:pl-[50px] lg:pt-[95px] pt-[95px] max-h-screen overflow-y-auto relative flex justify-start flex-col lg:p-[25px] p-[20px] h-auto min-h-screen bg-gray-200">
        <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[90%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <div class="w-full flex flex-col lg:flex-row justify-center items-center relative">
          <div class="w-full px-[20px] rounded-[10px] flex justify-end items-center">
            <button @click="toggleAddEmployeeForm" class="p-[10px] rounded-[5px] text-sm font-bold flex w-full md:w-auto justify-center items-center text-center text-white bg-gray-800">
              <i class="fa-solid fa-add mr-[5px]"></i>
              Ajouter
            </button>
          </div>
        </div>
        <div class="w-full flex flex-col lg:flex-row justify-center items-center flex-wrap relative">
          <div v-if="!isLoading && employes.length === 0" class="text-center w-full h-[200px] flex justify-center items-center font-bold text-gray-800">
            Aucun employé trouvé
          </div>
          <div v-if="isLoading" class="text-center h-[200px] w-full flex justify-center items-center font-bold text-gray-800">
            Chargement...
          </div>
          <div v-if="errorMessage" class="text-center w-full h-[200px] flex justify-center items-center text-red-500 font-bold">
            {{ errorMessage }}
          </div>
          <div v-else class="w-full py-[20px] rounded-[10px] lg:ml-[20px] flex lg:justify-start justify-center items-center flex-wrap">
            <div v-for="(employe, index) in employes" :key="index" class="m-[10px] shadow-4xl rounded-[10px] h-[280px] lg:w-[calc(100%/4-80px)] w-full relative lg:min-w-[260px] bg-white p-[15px]">
              <div class="flex w-full pb-[10px] border-b-[0.5px] border-gray-300 items-center">
                <div class="w-[50px] h-[50px] min-w-[50px] min-h-[50px] rounded-[50%] relative flex justify-center overflow-hidden items-center">
                  <img src="../assets/images/icone.png" class="w-full h-full absolute object-cover" alt="">
                </div>
                <div class="mx-[5px] flex flex-col">
                  <h1 class="text-sm font-bold text-gray-800">{{ employe.firstname }} {{ employe.lastname }}</h1>
                  <h2 class="text-sm font-semibold text-gray-400">{{ employe.poste }} - {{ employe.boutique }}</h2>
                </div>
              </div>
              <div class="flex pl-[10px] flex-col w-full relative">
                <div class="flex items-center my-[8px]">
                  <div class="w-[30px] h-[30px] flex text-gray-500 justify-start items-center">
                    <i class="fa-solid fa-envelope"></i>
                  </div>
                  <h1 class="text-sm text-gray-500 font-semibold">{{ employe.email }}</h1>
                </div>
                <div class="flex items-center my-[8px]">
                  <div class="w-[30px] h-[30px] flex text-gray-500 justify-start items-center">
                    <i class="fa-solid fa-phone"></i>
                  </div>
                  <h1 class="text-sm text-gray-500 font-semibold">+229 {{ employe.phone }}</h1>
                </div>
                <div class="flex items-center my-[8px]">
                  <div class="w-[30px] h-[30px] flex text-gray-500 justify-start items-center">
                    <i class="fa-solid fa-user"></i>
                  </div>
                  <h1 class="text-sm text-gray-500 font-semibold">{{ employe.username }}</h1>
                </div>
              </div>
              <button v-if="employe.isActive" @click="toggleActive(employe._id)" class="w-full rounded-[5px] p-[10px] text-white bg-red-600 text-xs font-bold mt-[10px]">
                Désactiver
              </button>
              <button v-else @click="toggleActive(employe._id)" class="w-full rounded-[5px] p-[10px] text-white bg-green-600 text-xs font-bold mt-[10px]">
                Activer
              </button>
              <button @click="toggleBox(employe._id)" class="absolute top-[-15px] right-[-15px] w-[35px] h-[35px] rounded-[50%] bg-gray-200 flex justify-center items-center">
                <i class="fa-solid fa-pen"></i>
              </button>
              <div v-if="showBoxId === employe._id" class="fixed inset-0 h-full w-full left-[50%] lg:left-[62%] translate-x-[-50%] top-[50%] translate-y-[-50%] flex items-center justify-center bg-gray-800/40 bg-opacity-50 z-50">
                <div class="bg-white rounded-lg shadow-lg w-[90%] max-w-md p-6 relative">
                  <h2 class="text-2xl font-bold mb-4">Modifier le profile de {{ employe.firstname }} {{ employe.lastname }}</h2>
                  <form @submit.prevent="modifieEmployee(employe._id)" class="w-full relative">
                    <div class="w-full flex flex-col relative my-[10px]">
                      <label for="poste" class="text-sm font-bold">Poste <span class="text-red-500">*</span></label>
                      <select v-model="poste" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" required>
                        <option value="Vendeur(se) Téléphone">Vendeur(se) Téléphone</option>
                        <option value="Vendeur(se) Accessoires">Vendeur(se) Accessoires</option>
                        <option value="Manager">Manager</option>
                        <option value="Vendeur(se) Accessoires et Téléphone">Vendeur(se) Accessoires et Téléphone</option>
                      </select>
                    </div>
                    <div class="w-full flex flex-col relative mt-[25px]">
                      <button type="submit" class="p-[10px] px-[20px] text-sm font-bold bg-gray-800 text-white rounded-[5px]" :disabled="isSubmitting">
                        <span v-if="isSubmitting" class="loader"></span>
                        <span v-else>Modifier</span>
                      </button>
                    </div>
                  </form>
                  <button @click="toggleBox(employe._id)" class="absolute top-[-15px] left-[50%] translate-x-[-50%] w-[35px] h-[35px] rounded-[50%] bg-gray-200 flex justify-center items-center">
                    <i class="fa-solid fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showAddEmployeeForm" class="min-h-[200px] h-auto max-w-[450px] w-[calc(100%-40px)] shadow-10xl z-[55] overflow-y-auto fixed p-[10px] flex justify-start items-center flex-col right-[20px] bottom-[20px] top-[90px] rounded-[10px] bg-white">
          <div class="flex w-full justify-between items-center lg:px-[20px] my-[10px]">
            <h1 class="text-sm font-bold text-gray-800">Ajouter un employé</h1>
            <div class="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-gray-100" @click="toggleAddEmployeeForm">
              <i class="fa-solid fa-times cursor-pointer"></i>
            </div>
          </div>
          <div class="mb-[5px] md:px-[20px] relative w-full">
            <div class="w-full flex pt-[20px] justify-center items-center flex-col relative">
              <form @submit.prevent="addEmployee" class="w-full relative">
                <div class="flex w-full relative md:justify-between items-center justify-center md:flex-row flex-col ">
                  <div class="my-[10px] md:w-[calc(50%-10px)] w-full relative flex flex-col">
                    <label for="firstname" class="text-sm font-bold">Prénoms <span class="text-red-500">*</span></label>
                    <input v-model="newEmployee.firstname" type="text" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" required>
                  </div>
                  <div class="my-[10px] md:w-[calc(50%-10px)] w-full relative flex flex-col">
                    <label for="lastname" class="text-sm font-bold">Nom <span class="text-red-500">*</span></label>
                    <input v-model="newEmployee.lastname" type="text" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" required>
                  </div>
                </div>
                <div class="w-full flex flex-col relative my-[10px]">
                  <label for="email" class="text-sm font-bold">Email <span class="text-red-500">*</span></label>
                  <input v-model="newEmployee.email" type="email" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" required>
                </div>
                <div class="w-full flex flex-col relative my-[10px]">
                  <label for="phone" class="text-sm font-bold">Numéro <span class="text-red-500">*</span></label>
                  <input v-model="newEmployee.phone" type="text" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" required>
                </div>
                <div class="w-full flex flex-col relative my-[10px]">
                  <label for="poste" class="text-sm font-bold">Poste <span class="text-red-500">*</span></label>
                  <select v-model="newEmployee.poste" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" required>
                    <option value="Vendeur(se) Téléphone">Vendeur(se) Téléphone</option>
                    <option value="Vendeur(se) Accessoires">Vendeur(se) Accessoires</option>
                    <option value="Manager">Manager</option>
                    <option value="Vendeur(se) Accessoires et Téléphone">Vendeur(se) Accessoires et Téléphone</option>
                  </select>
                </div>
                <div class="w-full flex flex-col relative my-[10px]">
                  <label for="boutique" class="text-sm font-bold">Boutique <span class="text-red-500">*</span></label>
                  <select v-model="newEmployee.boutique" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" required>
                    <option value="Cotonou">Cotonou</option>
                    <option value="Parakou">Parakou</option>
                  </select>
                </div>
                <div class="w-full flex flex-col relative mt-[25px]">
                  <button type="submit" class="p-[10px] px-[20px] text-sm font-bold bg-gray-800 text-white rounded-[5px]" :disabled="isSubmitting">
                    <span v-if="isSubmitting" class="loader"></span>
                    <span v-else>Ajouter</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>
  
<script setup>
import { ref, onMounted, computed } from 'vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import axios from 'axios';
import { useAuthStore } from '@/store/auth';
import { useRouter } from 'vue-router';
const router = useRouter();

const showBoxId = ref(null);
function toggleBox(id) {
  showBoxId.value = showBoxId.value === id ? null : id;
}

const employes = ref([]);
const isLoading = ref(true);
const errorMessage = ref('');
const successMessage = ref('')
const showAddEmployeeForm = ref(false);
const isSubmitting = ref(false);
const poste = ref('')
const authStore = useAuthStore();
const user = computed(() => authStore.user);

const newEmployee = ref({
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  poste: '',
  boutique: '',
});

const toggleAddEmployeeForm = () => {
  showAddEmployeeForm.value = !showAddEmployeeForm.value;
  errorMessage.value = '';
};

const fetchEmployees = async () => {
  try {
    const response = await axios.get('/api/users');
    employes.value = response.data;
    isLoading.value = false;
  } catch (error) {
    errorMessage.value = 'Erreur lors du chargement des employés.';
    hideErrorMessageAfterDelay()
    isLoading.value = false;
  }
};

const addEmployee = async () => {
  isSubmitting.value = true;
  try {
    const response = await axios.post('/api/users', newEmployee.value);
    employes.value.push(response.data);
    toggleAddEmployeeForm();
    newEmployee.value = {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      poste: '',
      boutique: '',
    };
    errorMessage.value = '';
  } catch (error) {
    errorMessage.value = 'Erreur lors de l\'ajout de l\'employé.';
    hideErrorMessageAfterDelay()
  } finally {
    isSubmitting.value = false;
  }
};

const toggleActive = async (id) => {
  try {
    await axios.put(`/api/users/${id}/toggle-active/`);
    const index = employes.value.findIndex((e) => e._id === id);
    employes.value[index].isActive = !employes.value[index].isActive;
    fetchEmployees()
  } catch (error) {
    errorMessage.value = 'Erreur lors du changement de statut.';
    hideErrorMessageAfterDelay()
  }
};

const modifieEmployee = async (id) => {
  try {
    await axios.put(`/api/update-poste/${id}`, {poste: poste.value});
    showBoxId.value = null,
    successMessage.value = "Profil de l'utilisateur modifié avec succès"
    hideSuccessMessageAfterDelay()
    fetchEmployees()
  } catch (error) {
    errorMessage.value = 'Erreur lors du changement de statut.';
    hideErrorMessageAfterDelay()
  }
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 5000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 10000);
};

onMounted(() => {
  fetchEmployees();
  if(!user.value.isSuper) {
    router.push("/dashboard")
  }
});
</script>

<style scoped>
/* Ajoutez vos styles ici */
.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #ea580c;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>  